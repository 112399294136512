<template>
  <div v-if="shouldShowBanner" ref="maintenance-message">
    <div class="alert alert-warning mb-5">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-warning"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
        </div>
        <div class="flex-grow-1 ml-2">
          You are currently visiting mini-my. Tuesdays 07:00 - 08:15 (Warwick Time) is the my.wbs at risk period
          when we perform essential updates and maintenance. Whilst my.wbs is unavailable and to ensure a smooth
          experience, users are automatically redirected to mini-my during this time.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeService} from "@/services/time.service";

export default {
  name: 'MaintenanceAlert',

  inject: {
    timeService: {
      default: () => timeService
    }
  },

  data() {
    return {
      maintenanceStart: { hour: 7, minute: 0 },
      maintenanceEnd: { hour: 8, minute: 15 }
    }
  },

  computed: {
    shouldShowBanner() {
      const isVisible = this.isMaintenanceWindow();
      this.$emit('maintenance-alert-status', isVisible);
      return isVisible
    }
  },

  methods: {
    isMaintenanceWindow() {
      const dateTimeToLocale = this.timeService.getCurrentTime();
      const currentDateTime = new Date(dateTimeToLocale);

      if (currentDateTime.getDay() !== 2) return false;

      const startTime = new Date(dateTimeToLocale);
      startTime.setHours(this.maintenanceStart.hour, this.maintenanceStart.minute, 0);

      const endTime = new Date(dateTimeToLocale);
      endTime.setHours(this.maintenanceEnd.hour, this.maintenanceEnd.minute, 0);

      return currentDateTime >= startTime && currentDateTime <= endTime;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped>
.text-warning {
  color: #ffc107;
}
</style>